/*eslint-disable */
import moment from 'moment';
import {bus} from '@/main'; 
import createMeetingPopup from '../create-meeting/';
export default {
  name: 'meetings',
  components: {
    'create-meeting' : createMeetingPopup
  },
  props: ['ngoInfoDATA', 'sideBaarExapandSTATE'],
  data () {
    return {
      ngoMeetingInfoData:null,
      ngoMeetingInfoDataResults:[],
      createMeetingPopupState:false,
      selfUserId:'',
      selfUserInfo:null,
      showExtraField:Boolean,
      ngoID:''
    }
  },
  computed: {
    getNgoMeetingListFun() {
      return this.$store.getters.getMeetingListOfSupportGroupGetter;
    },
    showExtraFieldFun(){
      return this.sideBaarExapandSTATE;
    }
  },
  mounted () {
    this.ngoID = this.$route.params.Id
    this.showExtraField = this.sideBaarExapandSTATE
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
      this.selfUserInfo = JSON.parse(localStorage.getItem('LoginedUserInfo'))
    }
    bus.$on('createMeetingPopupStateBus', (data) => {
      this.createMeetingPopupState = data
    });
    this.$store.dispatch('getMeetingListOfSupportGroupFunc', {id:this.ngoID}).then((res) => {
    })
  },
  methods: {
    startCountdownTimer(joinStartTime,joinEndTime){
      var currentLocalDate = moment().format('MMMM Do YYYY');
      var startDateCheck = moment(joinStartTime).format('MMMM Do YYYY')
      var currentLocalHours = moment().format('HH')
      var currentLocalTime = moment().format('mm:ss')
      var currentLocalSec = moment().format('ss')
      var startTimeHours = moment(joinStartTime).format('HH')
      var startTimeCheck = moment(joinStartTime).format('mm:ss')
      var startTimeSec = moment(joinStartTime).format('mm:ss')
      if(startDateCheck === currentLocalDate){
          var totalMinutesOfStartMeeting  = startTimeHours*parseInt(60) + parseInt(startTimeCheck);
          var totalMinutesOfCurrent  = currentLocalHours*parseInt(60) + parseInt(currentLocalTime);
          if(totalMinutesOfStartMeeting > totalMinutesOfCurrent){
            var validTime = totalMinutesOfStartMeeting - totalMinutesOfCurrent
            // console.log(validTime, "Checking Hours to Mint")
            if(parseInt(11) > validTime){
              this.counterText = validTime + ':' + '00';
              this.countdownTimer()
              return this.counterText;
            }else{
              return false;
            }
          }else{
            return false;
          }
      }else{
        return false;
      }
    },
    countdownTimer() {
      var __self = this
      clearInterval(interval);
      interval = setInterval( function() {
          var timer = $('.js-timeout').html();
          timer = timer.split(':');
          var minutes = timer[0];
          var seconds = timer[1];
          seconds -= 1;
          if (minutes < 0) return;
          else if (seconds < 0 && minutes != 0) {
              minutes -= 1;
              seconds = 59;
          }
          else if (seconds < 10 && length.seconds != 2) seconds = '0' + seconds;
          $('.js-timeout').html(minutes + ':' + seconds);
          if (minutes == 0 && seconds == 0){
            __self.getMeetingListAgain()
            // window.location.reload()
            clearInterval(interval)
          };
      }, 1000);
    },
    enabledJoinBtn(joinStartTime, joinEndTime) {
      var currentLocalDate = moment().format('MMMM Do YYYY');
      var currentLocalTime = moment().format('HH:mm:ss');
      var startDateCheck = moment(joinStartTime).format('MMMM Do YYYY')
      var startTimeCheck = moment(joinStartTime).format('HH:mm:ss')
      var endTimeCheck = moment(joinEndTime).format('HH:mm:ss')
      if(startDateCheck === currentLocalDate){
        if (currentLocalTime > startTimeCheck && endTimeCheck > currentLocalTime){
          return true;
        }else{   
          return false;
        }
      }else{
        return false;
      }
    },
    statusCheckOfMeeting(startTime){
      var curentDateUTC = moment().format();
      if (startTime > curentDateUTC){
        return true;
      }else{   
        return false;
      }
    },
    getCurrentDateTimeWithUTC() {
         var CurrentDateFormat = this.curentDate.getDate();
         var CurrentYearFormat = this.curentDate.getFullYear();
         var CurrentMonthFormat = this.curentDate.getMonth() + 1;

         var CurrentDateHoursFormat = this.curentDate.getHours();
         var CurrentDateMinutesFormat = this.curentDate.getMinutes();
         var CurrentDateSecondFormat = this.curentDate.getSeconds();

         this.fullCurrentDateTimeFormatWithUtc = CurrentYearFormat + "-" + CurrentMonthFormat + "-" + CurrentDateFormat + '-' + CurrentDateHoursFormat + '-' + CurrentDateMinutesFormat + '-' + CurrentDateSecondFormat
    },
    createMeetingPopupOpenMethod(){
      this.createMeetingPopupState = true
      document.body.classList.add('popup_open_body')
    }
  },
  watch:{
    getNgoMeetingListFun(newData){
      this.ngoMeetingInfoData = newData.data
      this.ngoMeetingInfoDataResults = [].concat(this.ngoMeetingInfoData.results)
    },
    showExtraFieldFun(newVal, oldVal){
      this.showExtraField = newVal
    }
  }
}